import React, {useState, useEffect} from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';

import { Link, useLocation } from "react-router-dom"

import useStyles from './styles';
import "./styles.css"
import { createPoll } from '../../actions/posts';

import Back from './../assets/comsButton1.png';
import Explore from './../assets/exploreButton2.png';
import ForCharacter from "./../assets/forCharacter.png"
import AgainstCharacter from "./../assets/againstCharacter.png"

import authBackground from "./../assets/authBackground.png"
import iphoneAuthBackground from "./../assets/iphoneAuthBackground.png"

import TickSuccess from "./../assets/success.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useHistory } from 'react-router-dom';

const PollSubmission = ({ currentId }) => {

    const [pollData, setPollData] = useState({ 
      position: "",
      statement: "",
      context: "",
  });
    const user = JSON.parse(localStorage.getItem("profile"))
    const dispatch = useDispatch();

    const [postError, setPostError] = useState(false)
    const [checkStatement, setCheckStatement] = useState("")
    const [open, setOpen] = useState(false);
    const [hideSubmissionBox, setHideSubmissionBox] = useState(true)
    const [postComplete, setPostComplete] = useState(false)
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const { posts } = useSelector((state) => state.posts);
 
    const [hasEnoughRecentPosts, setHasEnoughRecentPosts] = useState(false);

useEffect(() => {
  // Filter the posts to get only those created in the last 3 hours and have no more than 10 dislikes
  const filteredPosts = posts.filter(post => {
    const postTime = new Date(post.createdAt).getTime();
    // const threeHoursAgo = new Date(Date.now() - 3 * 60 * 60 * 1000).getTime();
    const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000).getTime();
    return postTime > twentyFourHoursAgo && post.dislikesTotal <= 10; // Add this line to filter out posts with more than 10 dislikes
  });
  
  // Check if there are at least 2 recent posts
  setHasEnoughRecentPosts(filteredPosts.length >= 10);
}, [posts]);


const handleSubmit = async (e) => {
  e.preventDefault();
  if (hasEnoughRecentPosts) {
    // console.log('more than 2');
    setPostError(true)
    setHideSubmissionBox(false);
  } else {
    // const updatedStatement = pollData.statement.trim().replace(/[.!?]+$/g, '') + '?';
    const updatedStatement = '"' + pollData.statement + '"';
    const updatedPollData = { ...pollData, statement: updatedStatement };
    await dispatch(createPoll({ ...updatedPollData, name: user?.result?.name, date: new Date() }));
    clear();
    setPostComplete(true);
    setHideSubmissionBox(false);
  }
};


  const clear = () => {
    setPollData({
      statement: "",
      context: "",
  });
  };
    
  if (!user?.result) {
    return (
      <div className={classes.authBackgroundImage}>
      <div className="authBackgroundImage1">
        <img className={classes.backgroundImageStyle} src={authBackground} alt=""/>
      </div>
      <div className="authBackgroundImage2">
        <img className={classes.backgroundImageStyle} src={iphoneAuthBackground} alt=""/>
      </div>
        <div style={{position: 'absolute', top: "5vh", left: 0, right: 0, bottom: 0}}>
    <div className={classes.paperSignIn}>

      <Typography className={classes.signInFlag}>
        Please <Link style={{textDecoration: "none"}}to="/auth"><span style={{textDecoration: "none", fontWeight: 700, color: "#838383"}}>sign in</span></Link> to interact on <span style={{fontFamily: "Orelega One", fontSize: "25px", fontWeight: 900}}>Syted</span>
    </Typography>
    </div>
    </div>
    </div>
    )
  }

    return (
      <>

{postComplete &&
      <div style={{height: "80vh"}}>
      <div className={classes.successNotificationContainer}>
        {/* <img src={ForCharacter} style={{width: "200px"}} alt=""/> */}
        {/* <p className={classes.successNotification}>Posted</p> */}
        <div style={{width: "150px", height: "150px"}}>
        <LazyLoadImage
                effect="blur"
                style={{    
                  maxWidth: "150px",
                }} 
                src={TickSuccess}
                alt=""/>
        </div>
        {/* <img style={{width: "150px"}} src={TickSuccess} alt=""/> */}
        <Link style={{textDecoration: "none"}} to="/topics">
          <Button className={classes.backToExploreButton}>
          <p className={classes.backToExploreButtonText}>Back to feed</p>
          </Button>
        </Link>
        </div>
        </div>
      }


{postError &&
      <div className={classes.errorNotificationContainer}>
        <p className={classes.errorNotification}>At this moment we've hit the 10 posts per 3 hour limit.</p>
        <p className={classes.errorNotification}>Please try again once more slots become available.</p>
        <Link style={{textDecoration: "none"}} to="/topics">
          <Button className={classes.backToExploreButton}>
          <p className={classes.backToExploreButtonText}>Back to feed</p>
          </Button>
        </Link>
        </div>
      }


{hideSubmissionBox &&
      <center style={{height: "100vh"}}>
        <div className="paper">
          <div className="paperBorder">
                <div onClick={history.goBack} className="backButtonContainer">Back
                  {/* <img className="backButtonIconStyle" src={Back} alt="Back" /> */}
                </div>

{/* {user?.result?.exclusive === true || user?.result?.exclusivePaid === true ? (
  <div className="exclusiveMenu">
    <Link to={"/submission"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
      <h1 className={`navBarOptions${location.pathname === "/submission" ? " active" : ""}`}>Public</h1>
    </Link>

    <Link to={"/submission-smarty-pants"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
      <h1 className={`navBarOptions${location.pathname === "/submission-smarty-pants" ? " active" : ""}`}>Exclusive</h1>
    </Link>
  </div>
) : null} */}


          <p className="submissionHeader">Post a statement</p>
          <p className="submissionDisclaimer">To encourage productive conversation, quotation marks will be added to your statement to indicate that it is a topic for debate.</p>
           {/* within the <span style={{fontFamily: "Orelega One", fontSize: "19px"}}>Syted</span> community */}
          <p className="submissionDisclaimerExample"><span style={{fontWeight: 700, fontSize: "18px", fontFamily: "Inconsolata"}}>e.g. "</span> College graduates should prioritise a gap year to develop skills, side hustles, or travel before committing to a full-time career <span style={{fontWeight: 700, fontSize: "18px", fontFamily: "Inconsolata"}}>"</span></p>
          {hasEnoughRecentPosts && <p className={classes.errorNotificationLive}>We've hit the 10 posts per 3 hour limit. <br/> Please try again once more slots become available.</p>}
      
          {!hasEnoughRecentPosts ? (
        <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
          <div className={classes.submissionContainer}>
      
          {/* <TextField fullWidth multiline minRows={3} required={true} name="statement" variant="standard" value={pollData.statement} onChange={(e) => setPollData({ ...pollData, statement: e.target.value })} InputProps={{ style: { fontFamily: "Urbanist", fontSize: "16px" }}} /> */}
          </div>

    
          <div className="submissionContainer2">
          <TextField placeholder='...'fullWidth multiline required={true} name="statement" variant="standard" value={pollData.statement} onChange={(e) => setPollData({ ...pollData, statement: e.target.value })} InputProps={{ style: { fontFamily: "Inconsolata", fontSize: "16px", color: "#000080"}}} />
          {/* <TextField fullWidth multiline name="context" variant="standard" label="Additional notes here" value={pollData.context} onChange={(e) => setPollData({ ...pollData, context: e.target.value })} InputProps={{ style: { fontFamily: "Inconsolata", fontSize: "15px" } }}/> */}

          </div>
          <Button className={classes.submissionContainerButton} 
          
          style={{width: "auto", margin: "5px 0px 0px 10px", padding: "10px 25px", backgroundImage: "radial-gradient(circle, #5b5956, #423F3E, #3D3B3C)", color: "#FFF", fontSize: "0.75rem", borderRadius: "4px"}} 
          
          type="submit" fullWidth>Post Statement</Button>
        </form>
        ) : (<></>) }
        </div>
      </div>
      </center>
}




      {!checkStatement && 
    <Snackbar
        open={open}
        style={{textAlign: "center", maxWidth: "300px", color: "#FE5E38", fontFamily: "Urbanist"}}
        onClose={(event, reason) => {
          setOpen(false);
        }}
        message="Oops, make sure you add your statement!"
      />
}
      </>
    )
}

export default PollSubmission
