import React, {useState, useEffect} from 'react'
import { Typography, Toolbar, Button } from '@material-ui/core';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from "jwt-decode"

import useStyles from './styles';

import "./styles.css"
import Stack from "./images/menu.png"
import Cross from "./images/cross.png"

export const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  // SANDWICH START
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (showDropdown && !event.target.closest('.your-dropdown-class')) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnOutsideClick);
    
    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick);
    };
  }, [showDropdown]);

// SANDWICH END


  const logout = () => {
    dispatch({ type: "LOGOUT" });

    history.push('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token

    if (token) {
      const decodedToken = decode(token)

      // if(decodedToken.exp * 2000 < new Date().getTime()) logout()
      if(decodedToken.exp * 1000 < new Date().getTime()) 
      logout()
      // history.push('/auth')
    }
    setUser(JSON.parse(localStorage.getItem("profile")))
  }, [location])




  return (
    <div className="appBar" position="static">
      <div className={classes.brandContainer}>
         {/* <Link to="/home" style={{ textDecoration: "none" }}>  */}
        <Link to="/" style={{ textDecoration: "none" }}>
          <h1 className="heading" align="center">sussed</h1>
        </Link>


      </div>

      {/* {user?.result && 
        <Link to={"/posts"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions">Collection</h1>
        </Link>
      } */}
{/* 
{location.pathname !== "/" && location.pathname !== "/posts" && user?.result && (
        <Link to={"/posts"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions">Collection</h1>
        </Link>
      )} */}


<div className="nonSandwichBarMenu">
{user?.result && 
    <Link to={"/topics"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
      <h1 className={`navBarOptions${location.pathname === "/" || location.pathname === "/explore" || location.pathname === "/you-smarty-pants"   ? " active" : ""}`}>Topics</h1>
    </Link>
      }
</div>


<div className="nonSandwichBarMenu">
{user?.result && 
    <Link to={"/explore"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
      <h1 className={`navBarOptions${location.pathname === "/" || location.pathname === "/explore" || location.pathname === "/you-smarty-pants"   ? " active" : ""}`}>Current</h1>
    </Link>
      }
</div>

{/* <div className="nonSandwichBarMenu">
{user?.result && 
  <Link to={"/week"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
          <h1 className={`navBarOptions${location.pathname === "/week" ? " active" : ""}`}>Week</h1>         </Link> 
      }
</div> */}


<div className="nonSandwichBarMenu">
{user?.result && 
 <Link to={"/top"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
           <h1 className={`navBarOptions${location.pathname === "/top" ? " active" : ""}`}>Top</h1>
       </Link>
}
      </div>


{/* <div className="nonSandwichBarMenu">
{user?.result && 
    <Link to={"/about"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
    <h1 className={`navBarOptions${location.pathname === "/about" || location.pathname === "/about" || location.pathname === "/about"   ? " active" : ""}`}>What?</h1>
  </Link>
      }
      </div> */}


{/* MY POSTS - decomissioned */}
{/* <div className="nonSandwichBarMenu">
{user?.result && 
         <Link to={"/my-posts"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
                    <h1 className={`navBarOptions${location.pathname === "/my-posts" ? " activeSmall" : ""}`}>My Posts</h1>
                 </Link>
}
</div> */}


<div className="nonSandwichBarMenu">



            <div className="toolbar">




        
              {user?.result ? (
                <div className={classes.profile}>
                  <div className="profileSection__responsive">
                    <div style={{ display: "flex", flexDirection: "row", textDecoration: "none", margin: "0px 0px 20px" }}>
                      <Typography className={classes.userName}>{user.result.name}</Typography>
                    </div>
                    <Button 
                      style={{ margin: "0px 0px 0px 15px", padding: "0px 17px", fontSize: "13px"  }}
                      className={classes.logout} onClick={logout}>Logout</Button>
                  </div>
                </div>
              ) : (
                <></>
                // <Link style={{ textDecoration: "none" }} to={"/auth"}>
                //   <Button 
                //     style={{ borderRadius: "18px", padding: "0px 20px", fontSize: "13px" }}
                //     className={classes.signInButton}
                //     variant="contained">Sign in</Button>
                // </Link>
              )}
            </div>
</div>


{!user?.result ? (
<>
  <Link style={{ textDecoration: "none" }} to={"/auth"}>
    <Button 
        style={{ padding: "0px 20px", fontSize: "13px" }}
        className={classes.signInButton}
       >Sign in</Button>
  </Link>
</>
) : (
<>            
<div className="sandwichBarMenu">
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <div style={{cursor: "pointer", borderRadius: "5px", padding:"2px 5px 0px"}} onClick={handleDropdownToggle}>
          {/* ☰ */}
          {showDropdown ? (
          <img style={{width: "22px"}} src={Cross} alt="☰"/>
          ) : (
          <img style={{width: "22px"}} src={Stack} alt="☰"/>
          )}
          
        </div>

        {showDropdown && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              right: 0,
              padding: "10px",
              background: '#3D3B3C',
              // border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
            }}
          >

{user?.result && 
    <Link to={"/topics"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
    <h1 className={`navBarOptionsToolBar${location.pathname === "/" || location.pathname === "/explore" || location.pathname === "/topics"   ? " active" : ""}`}>Topics</h1>
  </Link>
      }

{user?.result && 
    <Link to={"/explore"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
    <h1 className={`navBarOptionsToolBar${location.pathname === "/" || location.pathname === "/explore" || location.pathname === "/you-smarty-pants"   ? " active" : ""}`}>Current</h1>
  </Link>
      }

{user?.result && 
<Link to={"/top"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
      <h1 className={`navBarOptionsToolBar${location.pathname === "/top" || location.pathname === "/top-you-smarty-pants" ? " active" : ""}`}>Top</h1>
    </Link>
}

{/* {user?.result && 
    <Link to={"/about"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
    <h1 className={`navBarOptionsToolBar${location.pathname === "/about" || location.pathname === "/about" || location.pathname === "/about"   ? " active" : ""}`}>What?</h1>
  </Link>
      } */}

{/* MY POSTS - decommisioned */}
{/* {user?.result && 
<Link to={"/my-posts"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
      <h1 className={`navBarOptionsToolBar${location.pathname === "/my-posts" ? " activeSmall" : ""}`}>My Posts</h1>
    </Link>
} */}

            <Toolbar className="toolbar">
              {user?.result ? (
                <div className={classes.profile}>
                  <div className="profileSection__responsive">
                    {/* <Link to={"/profile"} style={{ display: "flex", flexDirection: "row", textDecoration: "none", margin: "15px 0px 15px" }}>
                      <p className="navBarOptionsToolBar">Profile</p>
                    </Link> */}
                    <Button 
                      style={{ borderRadius: "8px", margin: "0px 20px 15px", padding: "1px 18px", fontSize: "13px" }}
                      className={classes.logout} onClick={logout}>Logout</Button>
                  </div>
                </div>
              ) : (
                <></>
                // <Link style={{ textDecoration: "none" }} to={"/auth"}>
                //   <Button 
                //     style={{ borderRadius: "18px", padding: "0px 20px", fontSize: "13px" }}
                //     className={classes.signInButton}
                //     variant="contained">Sign in</Button>
                // </Link>
              )}
            </Toolbar>
          </div>
        )}
        </div>
      </div>
</>)}



{/* MOBILE */}

    </div>
  );
};

export default Navbar;



// import React, {useState, useEffect} from 'react'
// import { Typography, Toolbar, Avatar, Button } from '@material-ui/core';

// import { Link, useHistory, useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import decode from "jwt-decode"

// import useStyles from './styles';

// import "./styles.css"

// // PROFILE ASSETS:
// import Profile from "./images/profile.png"
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";


// export const Navbar = () => {
//   const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const history = useHistory();
//   const classes = useStyles();

//   const [menuOpen, setMenuOpen] = useState(false);


//   const logout = () => {
//     dispatch({ type: "LOGOUT" });

//     history.push('/');

//     setUser(null);
//   };

//   useEffect(() => {
//     const token = user?.token

//     if (token) {
//       const decodedToken = decode(token)

//       if(decodedToken.exp * 1000 < new Date().getTime()) logout()
//     }
//     setUser(JSON.parse(localStorage.getItem("profile")))
//   }, [location])



//   return (
//     <div className="appBar" position="static">
//     <div className={classes.brandContainer}>
//       <Link to="/home" style={{textDecoration: "none"}}>
//         <h1 className="heading" align="center">Syted</h1>
//       </Link>
// </div>


// {user?.result?.email && 
//     <div className="navBar-wide">
//     <Link to={"/explore"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//       <h1 className={`navBarOptions${location.pathname === "/" || location.pathname === "/explore" || location.pathname === "/you-smarty-pants"   ? " active" : ""}`}>Current</h1>
//     </Link>

//     {/* <Link to={"/week"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//       <h1 className={`navBarOptions${location.pathname === "/week" ? " active" : ""}`}>Week</h1>
//     </Link> */}

//     <Link to={"/top"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//       <h1 className={`navBarOptions${location.pathname === "/top" || location.pathname === "/top-you-smarty-pants" ? " active" : ""}`}>Top</h1>
//     </Link>

//     <Link to={"/my-posts"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//       <h1 className={`navBarOptions${location.pathname === "/my-posts" ? " activeSmall" : ""}`}>My Posts</h1>
//     </Link>
//     </div>
// }


// <div className='navBar-mobile'>
// {user?.result && 
//   <>
//     <IconButton
//       edge="start"
//       color="red"
//       onClick={() => setMenuOpen(!menuOpen)}
//       style={{  color: '#FE6E4B', margin: "0px auto"}}
//     >
//       <MenuIcon />
//     </IconButton>

//     {menuOpen && (
//       <div className={classes.menuItems}>
//         <Link to={"/"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//           {/* <h1 className={`navBarOptions${location.pathname === "/explore" ? " active" : ""}`}>Current</h1> */}
//           <h1 className={`navBarOptions${location.pathname === "/" || location.pathname === "/explore" ? " active" : ""}`}>Current</h1>
//         </Link>

//         {/* <Link to={"/week"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//           <h1 className={`navBarOptions${location.pathname === "/week" ? " active" : ""}`}>Week</h1>
//         </Link> */}

//         <Link to={"/top"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//           <h1 className={`navBarOptions${location.pathname === "/top" ? " active" : ""}`}>Top</h1>
//         </Link>

//         <Link to={"/my-posts"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//           <h1 className={`navBarOptions${location.pathname === "/my-posts" ? " activeSmall" : ""}`}>My Posts</h1>
//         </Link>
//       </div>
//     )}
//   </>
// }
// </div>


//       <Toolbar className="toolbar">
//         {user?.result ? (

// <div className={classes.profile}>
//   <div className="profileSection__responsive">

// <Link to={"/profile"} style={{display: "flex", flexDirection: "row", textDecoration: "none", margin: "3px 0px 15px"}}>

// <Avatar className={classes.purple} alt={user.result.name} img src={Profile}></Avatar>
//   <Typography className={classes.userName} variant="h1">{user.result.pseudoname}</Typography> 
// </Link>


// <Button 
// style={{borderRadius: "8px", margin: "10px 20px", padding: "6px 20px", fontSize: "13px"}}
//  className={classes.logout} onClick={logout}>Logout</Button>
// </div>
// </div>

//         ) : (
//           <Link style={{textDecoration: "none"}}  to={"/auth"}>
//           <Button 
//           style={{borderRadius: "8px",  padding: "10px 20px", fontSize: "13px"}}
//           className={classes.signInButton}
//           variant="contained">Sign up</Button>
//           </Link>
//         )}
//       </Toolbar>

//   </div>
//   )
// }

// export default Navbar;
