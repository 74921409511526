import React, {useState} from 'react';
import { Button, Modal, Box } from '@material-ui/core/';

import useStyles from './styles';
import "./styles.css"


// IMAGES:
import Downvote from "../assets/downVote.png"
import Upvote from "../assets/upVote.png"
// import Quotes from "../assets/quotes.png"
// import Comments from "../assets/comments.png"

import FillDownvote from "../assets/fillDownVote.png"
import FillUpvote from "../assets/fillUpVote.png"


import { useDispatch } from 'react-redux';

import { likePost, dislikePost, deletePost, flagPost } from '../../../actions/posts';

import MuiAlert from '@material-ui/lab/Alert';

import Bin from "../assets/bin.png"



const Topic = ({post, currentId, setCurrentId}) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));

  const dispatch = useDispatch();

  const [showDiscussion, setShowDiscussion] = useState(false)

    // FOR "SHARE" FEATURE
    const [open, setOpen] = useState(false);


    const [showDiv, setShowDiv] = useState(false);
  


    const handleMouseEnter = () => {
      setShowDiv(true);
    };




  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" className={classes.success} {...props} />;
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };



    const Likes = () => {
      if (post?.likes?.length > 0) {
        return post.likes.find((like) => like === (user?.result?._id))
          ? (
               <img className={classes.pollVoteAmplify} src={FillUpvote} alt="" />
          ) : (
              <img className={classes.pollVoteAmplify} src={Upvote} alt="" />
          );
      }
      return (
          <img className={classes.pollVoteAmplify} src={Upvote} alt="" />
      );
    };



    const Dislikes = () => {
      if (post?.dislikes?.length > 0) {
        return post.dislikes.find((like) => like === (user?.result?._id))
          ? (
              <img className={classes.pollVoteAmplify} src={FillDownvote} alt="" />
          ) : (
              <img className={classes.pollVoteAmplify} src={Downvote} alt="" />
          );
      }
      return (
          <img className={classes.pollVoteAmplify} src={Downvote} alt="" />
      );
    };


    const FlagPost = () => {
      if (post?.flags?.length > 0) {
        return post.flags.find((like) => like === (user?.result?._id))
          ? (
              <div> 
              <Button className={classes.selectTopicDashboardButtonWhiteDiscussion}>Unflag</Button>
              </div>
          ) : (
    <div> 
    <Button className={classes.selectTopicDashboardButtonWhiteDiscussion}>Flag</Button>
    </div>
          );
      }
    
      return (
    <div> 
    <Button className={classes.selectTopicDashboardButtonWhiteDiscussion}>Flag</Button>
    </div>
      );
    };

    
    // const now = new Date();
    function getTimeAgo(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return 'just now';
      }
      const now = new Date();
      const diffMs = now - date;
    
      if (diffMs < 60000) {
        return 'just now';
      } else if (diffMs < 3600000) {
        const diffMins = Math.floor(diffMs / 60000);
        return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
      } else if (diffMs < 86400000) {
        const diffHrs = Math.floor(diffMs / 3600000);
        return `${diffHrs} hour${diffHrs === 1 ? '' : 's'} ago`;
      } else {
        const diffDays = Math.floor(diffMs / 86400000);
        return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
      }
    }


    const toggleDiscussion = () => {
      setShowDiscussion(!showDiscussion);
    };

    return (
      <>

      <div className="containerTopicFull">

      <div className="topicContainerHeader">
<p className={classes.topicStyleDateTextStyle}>{getTimeAgo(post.createdAt)}</p>

{(user?.result?.googleId || user?.result?._id) && (
        <>
        <FlagPost post={post} setCurrentId={setCurrentId} />
        </>
      )}

{(user?.result?.googleId === post?.creator || user?.result?._id === post?.creator) && (
        <>
        <NestedModal post={post} setCurrentId={setCurrentId} />
        </>
      )}
</div>


<div className={classes.pollVoteContainer}>

<Button style={{borderRadius: "4px", margin: "0px 2px", padding: "0px"}} color="primary" disabled={!user?.result} onClick={() => dispatch(dislikePost(post._id))}>
    <Dislikes />
</Button>


<ReadMoreComponent post={post}/>

<Button style={{borderRadius: "4px", margin: "0px 2px", padding: "0px"}} color="primary" disabled={!user?.result} onClick={() => dispatch(likePost(post._id))}>
    <Likes />
</Button>

</div>
      </div>
      </>
    )
}

export default Topic





const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: "#3D3B3C",
  color: "#e3e0dc",
  textAlign: "center",
  fontFamily: 'Urbanist',
  fontSize: "20px",
  // border: '1px solid #e3e0dc',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "7px",
};


// ARGUMENT MODAL SUBMISSION:
function NestedModal({post}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div> 
      {/* <Button className={classes.selectArgumentDashboardButtonWhite}  */}
        <Button className={classes.selectTopicDashboardButtonWhiteDiscussion} 
        onClick={handleOpen}
        >
         {/* <img style={{width: "16px"}} src={Bin} alt=""/> */}
         Delete
         </Button>
        </div> 
           
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ ...style, width: 250 }}>
        <div className={classes.addArgumentInnerContainer}>
        <div style={{ width: '100%'}}>
          <p className={classes.addArgumentPreHeaderPrompt}>Are you sure you want to delete your post?</p>
          {/* <p className={classes.addArgumentHeader}>"{post.statement}"</p> */}
          {/* <ReadMoreComponentDelete post={post}/> */}
          <div className={classes.deleteArgumentAlign}>
          <Button style={{ fontSize: "0.9rem", fontWeight: 700, width: "45%", backgroundColor: "#e3e0dc", color: "#3D3B3C", margin: "8px", borderRadius: "4px", fontFamily: "Open Sans"  }} fullWidth variant="contained" onClick={handleClose}>
            No
          </Button>
          <Button style={{ fontSize: "0.9rem", fontWeight: 700, width: "45%", backgroundColor: "#e3e0dc", color: "#3D3B3C", margin: "8px", borderRadius: "4px", fontFamily: "Open Sans"  }} fullWidth variant="contained" 
            onClick={() => {
            dispatch(deletePost(post._id))
            handleClose()
          }}>
            Yes
          </Button>
          </div>
        </div>
      </div>
        </Box>
      </Modal>
    </div>
  );
}










function ReadMoreComponent({ post, setCurrentId }) {
  const [showFullStatement, setShowFullStatement] = useState(false);
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));



  const handleReadMoreClick = () => {
    setShowFullStatement(!showFullStatement);
  };

  return (
    <div>

      <p className="topicHeaderStatement">
        {showFullStatement ? post.statement : post.statement.substr(0, 380)}
        {post.statement.length > 400 && (
          <span className="readMoreButtonArgument" onClick={handleReadMoreClick}>
            {showFullStatement ? "...read less" : "...read more"}
          </span>
        )}
      </p>

    </div>
  );
}





function ReadMoreComponentDelete({ post }) {
  const [showFullStatement, setShowFullStatement] = useState(false);
  const classes = useStyles();

  const handleReadMoreClick = () => {
    setShowFullStatement(!showFullStatement);
  };

  return (
    <div>
      <p className={classes.pollHeaderStatementDelete}>
            {/* {showFullStatement ? post.statement : post.statement.substr(0, 200)} */}
            {showFullStatement ? `"${post.statement}"` : `"${post.statement.substr(0, 200)}"`}
        {post.statement.length > 400 && (
          <span className={classes.readMoreButtonArgumentDelete} onClick={handleReadMoreClick}>
            {showFullStatement ? "...read less" : "...read more"}
          </span>
        )}
      </p>
    </div>
  );
}








